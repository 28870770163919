import '../../assets/plugins/react-table.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import React from "react";
import ReactTable from 'react-table';
import tableStyle from "../../assets/components/tableStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import classNames from 'classnames';

function filterCaseInsensitive(filter, row) {  
  const id = filter.pivotId || filter.id;
	return (
    row[id] !== undefined
    ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
		: false
	);
}

function CustomTable({ ...props }) {
  const {
    tableHead,
    tableData,
    sortable,
    defaultPageSize,
    showPaginationTop,
    showPaginationBottom,
    loading,
    SubComponent,
    onRowClick,
    t,
    filterable,
    setRef,
    customClasses,
  } = props;
  return (
    loading
    ? <div style={{
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div> 
    : <ReactTable
      data={tableData}
      ref={(r) => {
        if (setRef != null)
           setRef(r);
      }}
      minRows={1}
      sortable={sortable}
      filterable={filterable}
      defaultPageSize={defaultPageSize}
      showPaginationTop={showPaginationTop}
      showPaginationBottom={showPaginationBottom}
      className={`-striped -highlight ${customClasses}`}
      columns={tableHead}
      defaultFilterMethod={filterCaseInsensitive}
      SubComponent={SubComponent}
      getTrProps={(state, rowInfo, column) => {
        let className = state.className ?? '';        
        if (rowInfo?.original?.disabled) {
          className += ' -disabledRow';
          }
        return {
          onClick: (e, te) => (
            onRowClick?.(e, te, rowInfo)
            ),
          className
        }
      }}
      // Text
      previousText={t("table.previous")}
      nextText={t("table.next")}
      loadingText={t("table.loading")}
      noDataText={t("table.empty")}
      pageText={t("table.page")}
      ofText={t("table.of")}
      rowsText={t("table.rows")}
    />
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  loading: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  filterable: false,
  sortable: false,
};

CustomTable.propTypes = {
  t: PropTypes.func,
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "secondary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  onRowClick: PropTypes.func,
  setRef: PropTypes.func,
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  colorsColls: PropTypes.array,
  customClasses: PropTypes.string,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  tableShopping: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  SubComponent: PropTypes.number,
};

export default withStyles(tableStyle)(withTranslation()(CustomTable));
