import "./Traslado.css";

import {
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import React, { Component } from "react";

import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Button from "../../CustomButtons/Button";
import CustomInput from "../../CustomInput/CustomInput";
import DateInput from "../../DateInput";
import Finished from "../../../assets/img/finished_trans.png";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Pending from "../../../assets/img/pending.png";
import PendingDo from "../../../assets/img/pending_do_trans.png";
import PinCurrent from "../../../assets/img/pin_current.png";
import PinDestination from "../../../assets/img/pin_destination.png";
import PinSource from "../../../assets/img/pin_source.png";
import PropTypes from "prop-types";
import Reject from "../../../assets/img/trasllat_cancel.png";
import SchedulePlan from "../../SchedulePlan";
import SelectInput from "../../SelectInput";
import TimePickerGCalendar from "../../TimePickerGCalendar";
import TrasladoPDF from "./TrasladoPDF.js";
import ValidationInput from "../../ValidationInput";
import Car from "../../../icons/Drivers";
import moment from "moment";
import { withTranslation } from "react-i18next";
import memory from "../../../config/memory";
import { Card, CardHeader, CardIcon } from "../../Card";
import Gps from "../../../icons/Gps";
import AccountBox from "../../../icons/AccountBox";
import { Grid, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const RegularMap = withScriptjs(
  withGoogleMap((props) => {
    return (
    <GoogleMap
      defaultZoom={14}
      center={{
        lat: parseFloat(props.center?.latitude),
        lng: parseFloat(props.center?.longitude),
      }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      {(props.customer || props.nosocomioVuelta) && (
        <Marker
          key={"customer"}
          className="routes-marker"
          defaultLabel={""}
          defaultIcon={{
            url: PinSource,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.mode ==="vuelta" ? props.nosocomioVuelta.latitude : props.customer.address?.[0].latitude),
            lng: parseFloat( props.mode ==="vuelta" ? props.nosocomioVuelta.longitude : props.customer.address?.[0].longitude),
          }}
        />
      )}
      {((props.nosocomio?.latitude)|| props.customerVuelta) && (
        <Marker
          key={"nosocomio"}
          className="routes-marker"
          defaultLabel={""}
          defaultIcon={{
            url: PinDestination,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.mode ==="vuelta" ? props.customerVuelta.address?.[0].latitude  : props.nosocomio.latitude),
            lng: parseFloat(props.mode ==="vuelta" ? props.customerVuelta.address?.[0].longitude  : props.nosocomio.longitude),
          }}
        />
      )}

      {props.currentPosition && (
        <Marker
          key={"currentposicion"}
          defaultLabel={""}
          className="routes-marker"
          defaultIcon={{
            url: PinCurrent,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={props.currentPosition}
        />
      )}

      {props.mode && props.mode === "inprogress" ? (
        <div>
          <Polyline
            options={{
              strokeOpacity: 0.7,
              strokeColor: "#099107",
              strokeWeight: 7,
            }}
            path={props.path1}
          />
          <Polyline
            path={props.path2}
            options={{
              strokeOpacity: 0.7,
              strokeColor: "#01abc2",
              strokeWeight: 7,
            }}
          />
        </div>
      ) : (
        <div>
          
          {
            props.mode ==="ida" ?
            <Polyline
            path={props.directionsIda?.[0]}
            options={{
              strokeOpacity: 0.7,
              strokeColor: "#098107",
              strokeWeight: 7,
            }}
          />:
          props.mode ==="vuelta" ?
          <Polyline
          path={props.directionsVuelta?.[0]}
          options={{
            strokeColor: props.colorStroke,
            strokeWeight: 7,
            strokeOpacity: 0.7,
          }}
        />:
        props.mode ==="idaVuelta" ?
          <>
          <Polyline
            path={props.directionsIda?.[0]}
            options={{
              strokeColor: "#098107",
              strokeWeight: 7,
              strokeOpacity: 0.7,
            }}
          />

          <Polyline
          path={props.directionsVuelta?.[0]}
          options={{
            strokeColor: props.colorStroke,
            strokeWeight: 7,
            strokeOpacity: 0.7,
          }}
        />
          </>
        
        :
          <Polyline
            path={props.directions[0]}
            options={{
              strokeOpacity: 0.7,
              strokeColor: props.colorStroke,
              strokeWeight: 7,
            }}
          />
          }
          
        </div>
      )}
    </GoogleMap>
    )
  })
);

class DialogoTraslado extends Component {
  constructor(props) {
    super(props);
    const { t  } = this.props;
    const initialNosocomios = props?.hospitals?.length > 0 ? props.hospitals : memory.nosocomios;
    const initialPrestadores = props?.prestadores?.length > 0 ? props.prestadores : memory.prestadores;
    this.state = {
      open: false,
      types: [
        {
          id: 1,
          value: t("Practice"),
        },
        {
          id: 2,
          value: t("Traslado"),
        },
      ],
      nosocomios: initialNosocomios,
      traslados: memory.traslados,
      prestadores: initialPrestadores,
      hospitalselected:props?.hospitals?.length > 0 ? props.hospitals : [],
      hospitalselectedVuelta:[],
      type: "",
      module: "",
      component: null,
      current: null,
      action: "",
      address: "",
      traslado: 0,
      prestador: 0,
      nosocomio: 0,
      appointmentAction: "",
      time_end_suggested: "-",
      distance_suggested: "-",
      time_end_suggested_return: "-",
      distance_suggested_return: "-",
      recurrenceType:'',
      waypoints: [],
      nosocomioObject: null,
      directions: [],
      directionsIda: [],
      directionsVuelta: [],
      recurrence: {},
      startTime: "02:00",
      startTimeDeparture:"10:00 pm",
      requestArrivalTimeDeparture:"10:00 pm",
      requestArrivalTimeReturn:'10:00 pm',
      withdrawalTimeReturn:"10:00 pm",
      rawPath: null,
      path1: [],
      path2: [],
      indexPosition: 0,
      currentPosition: null,
      pathExtra: null,
      timeSeconds:0,
      time_recorrend: 720,
      distance_recorred: 5100,
      roundTrip:0
    };
  }

  
  componentDidMount() {
    const {startDate, editData, viewMode} = this.props
    if(startDate){
      this.handleValue("shiftDateDeparture", startDate);
      this.handleValue("startDateDeparture", startDate);
    }

    if (editData?.length > 0) {
      this.setDataToEdit(this.props.editData);
    }
    if (memory.tempService?.type) {
      this.setState((prevState) => ({
        type: memory.tempService.type,
        startTime: memory.tempService.startTime,
        startDate: memory.tempService.startDate,
        recurrence: memory.tempService.recurrence,
        nosocomios: prevState.hospitals,
        prestadores: prevState.prestadores
      }));

      this.handleValue("traslado", memory.tempService.traslado.id);
      this.handleValue("nosocomio", memory.tempService.nosocomio.id);
    } else {
      this.handleValue("startTime", "09:00");
    }
    if (viewMode === "apointment") {
      let interval = setInterval(this.timer, 1000);
      this.setState({ interval: interval });
    }

    if (!window.google) {
      let interval2 = setInterval(this.checkMap, 1000);
      this.setState({ interval2: interval2 });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
    clearInterval(this.state.interval2);
  };

  checkMap = () => {
    if (window.google) {
      this.setDirection();
      clearInterval(this.state.interval2);
    }
  };

  timer = () => {
    if (this.state.rawPath != null) {
      let index = this.state.indexPosition;
      if (index >= this.state.rawPath.length) {
        clearInterval(this.state.interval)
      }
      let distance = 0;
      if (this.state.time_recorrend % 10 === 0) { distance = 100 }
      if (this.state.time_recorrend % 5 === 0) { this.dividePath(this.state.rawPath, index + 1) }

      this.setState(prevState => ({
        distance_recorred: prevState.distance_recorred + distance,
        time_recorrend: prevState.time_recorrend + 1
      }));
    }
  };
  
  formatNosocomio = (value)=>{
    const hospitalselected = this.props?.hospitals?.find(h => h.id == value);
    return hospitalselected
  }

  updateTime = (time_end, key) => {
    if (key === "nosocomioVuelta") {
      this.setState((prevState) => ({
        withdrawalTimeReturn: this.addSecondsToTime(prevState.requestArrivalTimeReturn, time_end)
      }));
      this.props.handleChangeValue('withdrawalTimeReturn', this.state.withdrawalTimeReturn);
    } else {
      this.setState((prevState) => {
        const startTimeDeparture = this.subtractSecondsFromTime(prevState.requestArrivalTimeDeparture, time_end);
    
        this.props.handleChangeValue('startTimeDeparture', startTimeDeparture);
    
        return { startTimeDeparture };
      });
    }
  };

  handleValue = (key, value) => {
    if (key === "requestArrivalTimeDeparture") {
      this.setState((prevState) => {
        const newState = {
          requestArrivalTimeDeparture: value,
          startTimeDeparture: this.subtractSecondsFromTime(value, prevState.time_end)
        };
        this.props.handleChangeValue('startTimeDeparture', newState.startTimeDeparture);
        this.props.handleChangeValue("requestArrivalTimeDeparture", value);
        return newState;
      });
      return;
    }
  
    if (key === "requestArrivalTimeReturn") {
      this.setState((prevState) => {
        const newState = {
          requestArrivalTimeReturn: value,
          withdrawalTimeReturn: this.addSecondsToTime(value, prevState.time_end)
        };
        this.props.handleChangeValue('withdrawalTimeReturn', newState.withdrawalTimeReturn);
        this.props.handleChangeValue("requestArrivalTimeReturn", value);
        return newState;
      });
      return;
    }
  
    if (key === "nosocomio") {
      const hospitalselected = this.formatNosocomio(value);
      this.setState({ hospitalselected }, () => {
        this.setDirection(key);
      });
  
      if (this.props.transferWay === 3) {
        const hospitalselectedVuelta = this.formatNosocomio(value);
        this.setState({ hospitalselectedVuelta }, () => {
          this.setDirection("nosocomioVuelta");
        });
      }
    } else if (key === "nosocomioVuelta") {
      const hospitalselectedVuelta = this.formatNosocomio(value);
      this.setState({ hospitalselectedVuelta }, () => {
        this.setDirection(key);
      });
    } else {
      this.setState({ [key]: value }, () => {
        if (key === "startTime") {
          this.recalculateStartTime();
        }
      });
    }
  
    if (this.props.handleChangeValue) {
      this.props.handleChangeValue(key, value);
    }
  
    
  };
  
  setDirection = (key) => {
    if (!this.props.customerDetail || !this.state.hospitalselected) {
      return;
    }
    
    let DirectionsService = null;
    try {
      DirectionsService = new window.google.maps.DirectionsService();
    } catch (error) {
      return;
    }

    let alternative = false;
    if (
      this.props.appointment?.appointmentId == 101 &&
      this.isTodayMenus(1)
    ) {
      alternative = true;
    }
    DirectionsService.route(
      {
        origin: {
          lat: key === "nosocomioVuelta" ? parseFloat(this.state?.hospitalselected?.latitude ?? this.state?.hospitalselectedVuelta?.latitude) : parseFloat(this.props.customerDetail.address?.[0].latitude),
          lng: key === "nosocomioVuelta" ? parseFloat(this.state?.hospitalselected?.longitude ?? this.state?.hospitalselectedVuelta?.longitude) : parseFloat(this.props.customerDetail.address?.[0].longitude),
        },
        destination: {
          lat:  key === "nosocomioVuelta" ? parseFloat(this.props.customerDetail.address?.[0].latitude) : parseFloat(this.state?.hospitalselected?.latitude ?? this.state?.hospitalselectedVuelta?.latitude),
          lng:  key === "nosocomioVuelta" ? parseFloat(this.props.customerDetail.address?.[0].longitude) : parseFloat(this.state?.hospitalselected?.longitude ?? this.state?.hospitalselectedVuelta?.longitude),
        },
        waypoints: [],
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: alternative,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          let distance_suggested = "-";
          let time_end = 0;
          if (result.routes.length > 0 && result.routes[0].legs.length > 0) {
            distance_suggested = result.routes[0].legs[0].distance["text"];
            time_end = result.routes[0].legs[0].duration["value"]
          }
          let raw = [];
          let extras = [];

          if (this.props.viewMode === "apointment") {
            raw = result["routes"][0]["overview_path"];
            let startp = parseInt(raw.length / 2)
            this.dividePath(raw, startp)
          }
          if (result["routes"].length > 1) {
            result["routes"][1]["overview_path"].map(function ( value, index, array) {
              extras.push(value)
            });
          }

          let directions = [];
          if (result["routes"].length > 0)
            directions.push(result["routes"][0]["overview_path"])
          if (result["routes"].length > 1)
            directions.push(result["routes"][1]["overview_path"])
          if (result["routes"].length > 2)
            directions.push(result["routes"][2]["overview_path"])
          let stateUpdate = {
            directions: directions,
            time_end: time_end,
            time_endVuelta: time_end,
            rawPath: raw,
            pathExtra: extras,
          };
          if (key === "nosocomioVuelta") {
            stateUpdate.directionsVuelta = directions;
          }
          if (key === "nosocomio") {
            stateUpdate.directionsIda = directions;
          }

          if (key === "nosocomioVuelta") {
            stateUpdate.distance_suggested_return = distance_suggested;
            this.props.handleChangeValue("distanceRoundTrip",distance_suggested)
            this.setState({distanceRoundTrip:distance_suggested})
            this.updateTime(time_end,key)
          } else {
            stateUpdate.distance_suggested = distance_suggested;
            this.props.handleChangeValue("distance",distance_suggested)
            this.setState({distance:distance_suggested})
            this.updateTime(time_end,key)
          }
          
          this.setState({timeSeconds:time_end})
          
          this.setState(stateUpdate,() => {
              this.recalculateStartTime(key,time_end)
            }
          );
        } else {
          console.error(`error fetching directions ${result}`)
        }
      }
    );
  };

  dividePath = (raw, indexPath) => {
    let currentPosition = {};
    let path1 = [];
    let path2 = [];
    raw.map(function (value, index, array) {
      if (index < indexPath) {
        path1.push(value);
      } else if (index === indexPath) {
        path1.push(value)
        path2.push(value)
        currentPosition = { latitude: value.lat(), longitude: value.lng() };
      } else {
        path2.push(value)
      }
    });
    this.setState({
      path1: path1,
      path2: path2,
      indexPosition: indexPath,
      currentPosition: currentPosition,
      rawPath: raw,
    });
  };

  setDataToEdit(data) {
    
    let hospital = null;
    AppointmentApiInvoker.getHospital((response) => {
      if (data.length > 0) {
        hospital = response.filter((id) => id.hospitalId == data[0].hospitalId);
        this.setState({
          traslado:
            data.length > 0
              ? this.formatTransferTypeSend(data[0].serviceName)
              : "",
          nosocomio: data.length > 0 ? data[0].hospitalId : null,
          nosocomioObject: hospital[0],
          startDate: data.length > 0 ? data[0].startDateSend : null,
          startTime: data.length > 0 ? data[0].startTime : null,
          recurrence:
            data.length > 0 ? { recurrenceType: data[0].recurrenceType } : null,
        });
      }
    });
  }

  formatTransferTypeSend = (type) => {
    if (type === "Traslado estándar") {
      return 1;
    } else if (type === "Traslado con rampa") {
      return 2;
    } else if (type === "Traslado con ambulancia") {
      return 3;
    }
  };

  recalculateStartTime = (key, time_end) => {
    const hrs = Math.floor(time_end / 3600);
    const mins = Math.floor((time_end % 3600) / 60);
    const secs = time_end % 60;
    const formattedDuration = secs ? `${hrs} hrs, ${mins} min, ${secs} seg` : '';

    this.setState(prevState => {
        let stateUpdate = {};
        if (key === "nosocomioVuelta") {
            stateUpdate.time_end_suggested_return = formattedDuration;
            this.props.handleChangeValue("durationRoundTrip",time_end)
        } else {
            stateUpdate.time_end_suggested = formattedDuration;
            this.props.handleChangeValue("duration",time_end)
        }
        return stateUpdate;
    });
};
  componentWillReceiveProps(next) {
    this.setState({ open: next.open });
  }


  subtractSecondsFromTime= (time, secondsToSubtract=0)=> {
    let momentTime = moment(time, 'hh:mm a');
    momentTime.subtract(secondsToSubtract, 'seconds');
    return momentTime.format('h:mm a');
  
  }

  addSecondsToTime = (time, secondsToAdd = 0) => {
      let momentTime = moment(time, 'hh:mm a');

      momentTime.add(secondsToAdd, 'seconds');
      return momentTime.format('h:mm a');
  };


  onCreatedRecurrence = (value) => {
    this.setState({
      recurrence: value,
    });
    this.props.handleChangeValue("recurrence", value);
  };

  invalidPlan = () => {
    return false;
  };

  isInvalid = () => {
    if (
      !this.state.nosocomio ||
      !this.state.traslado ||
      !this.state.startDate ||
      !this.state.recurrence ||
      !this.state.startTime
    ) {
      return true;
    } else {
      if (!this.state.recurrence.recurrenceType) return true
      if (
        this.state.recurrence.recurrenceType == "DAY" && this.state.recurrence.every <= 0
      )
        return true
      if (
        this.state.recurrence.recurrenceType == "MONTH" &&
        (!this.state.recurrence.monthRecurrence ||
          this.state.recurrence.monthRecurrence <= 0 ||
          this.state.recurrence.monthRecurrence > 31 || !this.state.recurrence.every || this.state.recurrence.every <= 0)
      )
        return true
      if (
        this.state.recurrence.recurrenceType == "WEEK" &&
        (this.state.recurrence.weekRecurrence.length == 0 || !this.state.recurrence.every || this.state.recurrence.every <= 0)
      )
        return true
      return false
    }
  };

  formatTimeSeconds = () => {
    let totalSeconds = this.state.time_recorrend
    let hours = Math.floor(totalSeconds / 3600) + ""
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60) + ""
    let seconds = (totalSeconds % 60) + "";
    return (
      hours.padStart(2, "0") + ":" +
      minutes.padStart(2, "0") + ":" +
      seconds.padStart(2, "0")
    );
  };

  isTodayMenus = (v) => {
    let nowmenusone = moment(new Date())
      .add(v * -1, "d")
      .format("YYYY-MM-DD");
    let event = moment(new Date(this.props?.event?.start)).format("YYYY-MM-DD");
    return nowmenusone === event;
  };

  toPDF = () => {
    let hora_real_retiro = "";
    let hora_real_fin = "";
    let hora_real_distance = "";
    let time_real = "";

    if (this.isTodayMenus(1)) {
      hora_real_retiro = "10:04";
      hora_real_fin = "10:29";
      hora_real_distance = "10.1 km";
      time_real = "00:25:00";
    } else if (this.isTodayMenus(2)) {
      hora_real_retiro = "10:00";
      hora_real_fin = "10:30";
      hora_real_distance = "10.4 km";
      time_real = "00:30:00";
    } else if (this.isTodayMenus(3)) {
      hora_real_retiro = "9:58";
      hora_real_fin = "10:26";
      hora_real_distance = "10.2 km";
      time_real = "00:28:00";
    } else if (this.isTodayMenus(4)) {
      hora_real_retiro = "10:02";
      hora_real_fin = "10:35";
      hora_real_distance = "10.2 km";
      time_real = "00:33:00";
    } else if (this.isTodayMenus(5)) {
      hora_real_retiro = "10:04";
      hora_real_fin = "10:32";
      hora_real_distance = "10.3 km";
      time_real = "00:28:00";
    }
  
    let context = {
      appointmentId: this.props.event.id,
      startDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      startTime: hora_real_retiro,
      realDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      realTime: hora_real_fin,
      address_fin: "Juan Cruz Varela 2634, Córdoba",
      trasladoName: this.props.appointment.services.traslado.value,
      financier:{
        financier: localStorage.getItem('itlg_default_company_name'),
        provider: localStorage.getItem('username')
      },
      customer: {
        firstName: this.props.appointment.customer.firstName,
        lastName: this.props.appointment.customer.lastName,
        address: this.props.appointment.customer.address_name,
        location: this.props.appointment.customer.location,
        province: this.props.appointment.customer.province,
        typeDocument: this.props.appointment.customer.typeDocument,
        document: this.props.appointment.customer.document,
      },
      chofer: {
        firstName: this.props.appointment.services.driver.firstName,
        lastName: this.props.appointment.services.driver.lastName,
        typeDocument: this.props.appointment.services.driver.typeDocument,
        document: this.props.appointment.services.driver.document,
      },
      nosocomio: {
        address: this.props.appointment.services.nosocomio.address,
        location: "Capital",
        province: "Córdoba",
      },
      trayecto: {
        distance: hora_real_distance,
        duration: time_real,
      },
    };
    TrasladoPDF(context);
  };

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  render() {
    const { t } = this.props;
    const { types, customerDetail } = this.state;
    let hospitals = this.props.hospitals
    let end_time = "end_time";
    let isDisabled = this.props.disabled || this.isInvalid();
    let viewMode = "short";
    let type= "ida";
    let center = this.props?.map?.center;

    let recurrenceTypes= [
      { id: 'DAY', value: 'Diaria', component: null },
      { id: 'WEEK', value: 'Semanal' },
      { id: 'MONTH', value: 'Mensual' },
      { id: 'INTRADAY', value: 'Interdiaria' },
    ];

    if (this.props.formType) {
      type = this.props.formType;
    }



    if (this.props.viewMode) {
      viewMode = this.props.viewMode;
    } else {
      viewMode =
        this.props.appointment &&
        this.props.appointment.appointmentStatus ===
          "TRANSFER_SCHEDULES_ASSIGNED"
          ? "large"
          : "short";
    }
    if (this.state.currentPosition) {
      center = this.state.currentPosition;
    }

    if (viewMode == "large") {
      let nextDay = false;
      let today = new Date();
      let dateEvent;
      if (this.props.event) {
        dateEvent = new Date(this.props.event.start);
      }
      if (dateEvent > today) {
        nextDay = true;
      }
      let istoday = this.isTodayMenus(0);
      let todaymenusone = this.isTodayMenus(1);
      let todaymenustwo = this.isTodayMenus(2);

      let icon = Pending;
      let status = "PENDING_APPROVAL";
      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 100
      ) {
        icon = Reject;
        status = "REJECTED";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101
      ) {
        if (istoday) {
          icon = PendingDo;
          status = "IN_PROGRESS";
        } else if (nextDay) {
          icon = PendingDo;
          status = "APPROVED";
        } else if (todaymenusone || todaymenustwo) {
          icon = Finished;
          status = "DONE";
        } else {
          icon = Finished;
          status = "DONE";
        }
      }

      let color = "#099107";
      if (nextDay) {
        color = "#01abc2";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101 &&
        this.isTodayMenus(1)
      ) {
        color = "#01abc2";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 100
      ) {
        color = "#01abc2";
      }
      let hora_real_retiro = "-";
      let hora_real_fin = "-";
      let hora_real_distance = "-";
      let finished = false;
      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101
      ) {
        if (this.isTodayMenus(0)) {
          hora_real_retiro = "10:04";
          color = "#01abc2";
        }
        if (this.isTodayMenus(1)) {
          hora_real_retiro = "10:04";
          hora_real_fin = "10:29";
          hora_real_distance = "10.1 km";
          finished = true;
        } else if (this.isTodayMenus(2)) {
          hora_real_retiro = "10:00";
          hora_real_fin = "10:30";
          hora_real_distance = "10.4 km";
          finished = true;
        } else if (this.isTodayMenus(3)) {
          hora_real_retiro = "9:58";
          hora_real_fin = "10:26";
          hora_real_distance = "10.2 km";
          finished = true;
        } else if (this.isTodayMenus(4)) {
          hora_real_retiro = "10:02";
          hora_real_fin = "10:35";
          hora_real_distance = "10.2 km";
          finished = true;
        } else if (this.isTodayMenus(5)) {
          hora_real_retiro = "10:04";
          hora_real_fin = "10:32";
          hora_real_distance = "10.3 km";
          finished = true;
        }
      }
      
      //const [transfer, setTransfer] = useState;
      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t('service.transferType')}
                  disabled={true}
                  value={this.formatTransferTypeRecive(
                    this.props.event?.origin?.transferItemRequestId?.transferType
                  )}
                  
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="Ida"
                  disabled={true}
                  value={this.props?.event?.id}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("service.driver")}
                  disabled={true}
                  value={
                    this.props.event?.origin?.driver
                      ? this.props.event?.origin?.driver?.firstName +
                        ", " +
                        this.props.event?.origin?.driver?.lastName
                      : "-"
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("service.start_date")}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={`${moment(
                    this.props.event?.origin?.startDateTime
                  ).format("DD/MM/YYYY")}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t("service.customer.address")}
                  disabled={true}
                  inputProps={{ style: { width: "100%" } }}
                  value={this.props.appointment?.customer?.address_name}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={`${t("service.startTimeProg")}`}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  value={`${moment(
                    this.props.event?.origin?.startDateTime
                  ).format("HH:mm")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("service.startTimeProgReal")}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={""}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t("service.nosocomio.address")}
                  elements={""}
                  disabled={true}
                  value={
                    this.props.event?.origin?.transferItemRequestId?.hospital
                      .businessName +
                    " " +
                    this.props.event?.origin?.transferItemRequestId?.hospital
                      .address
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <CustomInput
                  labelText={t("service.endTimeProg")}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  value={`${moment(this.props.event?.origin?.endDateTime)
                    .add(45, "minutes")
                    .format("HH:mm")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={`${t("service.endTimeReal")}`}
                  value={hora_real_fin}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={`${t("service.distance_suggested")}`}
                  value={"-"}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={`${t("service.distance_real")}`}
                  value={"-"}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  type="text"
                  iconName={icon}
                  disabled={true}
                  inputProps={{ style: { width: "100%" } }}
                  text={`${t("service.status")}`}
                  value={`${t(status)}`}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  text={`${t("service.distance")}`}
                  value={this.state.distance_suggested}
                  disabled={this.props.disabled}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  text={`${t("service.time_start_destination")}`}
                  disabled={this.props.disabled}
                  value={this.state.time_end_suggested}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                map={this.props.map}
                customer={this.props.customer}
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                mode=""
                center={center}
                colorStroke={color}
                nosocomio={
                  this.props.event?.origin?.transferItemRequestId?.hospital
                }
                loadingElement={<div style={{ height: `100%` }} />}
                googleMapURL="/googlemap"
                containerElement={
                  <div
                    style={{
                      borderRadius: "6px",
                      overflow: "hidden",
                      height: `500px`,
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            <GridContainer 
              style={{ textAlign: "right" }}
            >
              <GridItem xs={12} sm={12} md={12}>
                {finished && (
                  <Button
                    color="warning"
                    style={{ textAlign: "right" }}
                    onClick={() => this.toPDF()}
                  >
                    {t("service.download.pdf")}
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      );
    } else if (viewMode === "shorty") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date()).format( "YYYY-MM-DD" );
        max = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t('service.transferType')}
                elements={this.state.traslados}
                disabled={this.props.disabled}
                onSelectedValue={(value) => this.handleValue("traslado", value)}
                value={this.state.traslado}
              />
            </GridItem>
            <GridItem xs={12} className="margin-left">
              <div style={{'display':'flex','alignItems': 'center' }}>
                  <Gps/> 
                  <h4 className="label-services">{t("service.destination")}</h4>
              </div>
              
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t("service.nosocomio")}
                elements={this.props.hospitals && []}
                disabled={this.props.disabled}
                onSelectedValue={(value) =>
                  this.handleValue("nosocomio", value)
                }
                value={this.state.nosocomio}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationInput
                disabled={true}
                type="text"
                text={`${t("service.address")}`}
                value={
                  this.state.nosocomioObject
                    ? this.state.nosocomioObject.address
                    : ""
                }
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
                <DateInput
                  disabled={this.props.disabled}
                  text={t("service.start_date")}
                  minDate
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.startDate}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <TimePickerGCalendar
                  text={t("service.time_end_destination")}
                  name={end_time}
                  value={this.state.startTime}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>
                    this.handleValue("startTime", value)
                  }
                  defaultValue="02:00"
                  invalid={!this.state.startTime}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SchedulePlan
                  isTraslado={true}
                  insideModule={false}
                  current={this.state.recurrence}
                  indexArrayDays={0}
                  disabled={this.props.disabled}
                  invalidPlan={this.invalidPlan}
                  onCreatedRecurrence={this.onCreatedRecurrence}
                  className="schedule-plan-component"
                  onlyDate={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  text={`${t("service.distance")}`}
                  disabled={this.props.disabled}
                  type="text"
                  value={this.state.distance_suggested}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  disabled={this.props.disabled}
                  type="text"
                  value={this.state.time_end_suggested}
                  text={`${t("service.time_start_destination")}`}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  disabled={this.props.disabled}
                  value={this.state.distance_suggested}
                  text={`${t("service.distance")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  value={this.state.time_end_suggested}
                  text={`${t("service.time_start_destination")}`}
                  disabled={this.props.disabled}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                location={this.state.currentLocation}
                customer={this.props.customer}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                nosocomio={
                  this.state.appointment
                    ? this.state.appointment.nosocomio
                    : this.state.nosocomioObject
                }
                googleMapURL="/googlemap"
                mode=""
                center={center}
                colorStroke={"#01abc2"}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `500px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            {!this.props.appointment && (
              <GridContainer className="button-translado">
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    disabled={this.props.disabled}
                    onClick={() => this.props.openDrivers()}
                    color="primary"
                  >
                    {t("service.driver.assign")}
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    color={"success"}
                    disabled={isDisabled}
                    onClick={() => this.props.saveService()}
                  >
                    {t("service.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
        </GridContainer>
      );
    } else if (type === "ida") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date()).format("YYYY-MM-DD");
        max = moment(new Date()).format( "YYYY-MM-DD");
      }
      
      return (
        <Card id="card-customer" className="card-customer-form">
          <CardHeader icon>
              <CardIcon color="primary" >
                  <Car />
              </CardIcon>
              <h3 className="card-icon-title">{t("service.configuration")}</h3>
          </CardHeader>
          <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridItem xs={12} className="margin-left">
              <h2>
                {t("service.oneWay.label")}
              </h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={`${t("service.oneWay.label")}`}
                elements={this.state.traslados}
                disabled={this.props.disabled}
                onSelectedValue={(value) => this.handleValue("trasladoIda", value)}
                
                value={this.state.trasladoIda ?? this.props?.customerDetail?.trasladoIda}
              />
            </GridItem>
            <GridItem xs={12} className="margin-left">
              <div style={{'display':'flex','alignItems': 'center' }}>
                  <AccountBox/> 
                  <h4 >{t("service.origin")}</h4>
                  
              </div>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
              <ValidationInput
                type="text"
                text={`${t("service.address.only")}`}
                disabled={true}
                value={
                  this.props.customerDetail ? this.props.customerDetail.address?.[0].street +" - "+ this.props.customerDetail.address?.[0].number : ""
                }
              />
            </GridItem>
            <GridContainer>
              <GridItem  xs={12} sm={6}>
                <ValidationInput
                    text={`${t("service.floor")}`}
                    type="text"
                    value={
                      this.props.customerDetail ? this.props.customerDetail.address?.[0].floor +" " : ""
                    }
                    disabled={true}
                  />
                  
              </GridItem>
              <GridItem  xs={12} sm={6}>
                <ValidationInput
                    type="text"
                    disabled={true}
                    text={`${t("service.apartment")}`}
                    value={
                      this.props.customerDetail ? this.props.customerDetail.address?.[0].department +" " : ""
                    }
                  />
                  
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <DateInput
                  text={t("service.retirement.date")}
                  disabled={true}
                  
                  minDate
                  min={this.props.startDate}
                  max={this.props.endDate}
                  onChangeValue={(value) =>
                    this.handleValue("startDateDeparture", value)
                  }
                  value={this.state.startDateDeparture ?? this.props.customerDetail?.startDateDeparture}
                />
              </GridItem>
              <GridItem xs={11} sm={5} md={5} className="timeInput">
              <TimePickerGCalendar
                text={t("service.stimated.pickup.time")}
                value={this.state.startTimeDeparture  ?? this.props.customerDetail?.startTimeDeparture }
                name="startTimeDeparture"
                disabled={true}
                onSelectedValue={(value) => this.handleValue("startTimeDeparture", value)}
                defaultValue="10:00 am" 
                invalid={!this.state.startTimeDeparture && !this.props.customerDetail?.startTimeDeparture}
              />
              </GridItem>
              <Grid item xs={1} className="info-search" style={{ marginTop: '20px' }}>
                <Tooltip aria-label="info" title={t('tooltipInfo.wizzard')} placement="left-start">
                  <InfoOutlined />
                </Tooltip>
              </Grid>
            </GridContainer>
            <GridItem xs={12} className="margin-left">
            <div style={{'display':'flex','alignItems': 'center' }}>
                  <Gps/> 
                  <h4 className="label-services">{t("service.destination")}</h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t("service.medical.provider")}
                elements={this.props?.prestadores?.length > 0 ? this.props?.prestadores : this.state.prestadores}
                disabled={this.props.disabled}
                onSelectedValue={(value) =>{
                  this.handleValue("prestador", value)
                  this.handleValue("prestadorVuelta", value)
                  this.handleValue("prestadorResumen", value)
                }
                }
                value={this.props.customerDetail?.prestador ?? this.state.prestador }
              />
            </GridItem>
            <GridContainer>
              
              <GridItem xs={12} sm={12} md={12}>
                <SelectInput
                  label={t("service.hospital.destination")}
                  
                  elements={this.props?.hospitals?.length > 0 ? this.props?.hospitals : this.state?.nosocomios}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>{
                    this.handleValue("nosocomio", value)
                    this.handleValue("nosocomioVuelta", value)
                  }
                  }
                  value={this.props.customerDetail?.nosocomio ?? this.state.nosocomio  }
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <DateInput
                  text={t("service.arrival.date")}
                  disabled={true}
                  value={this.state.shiftDateDeparture ?? this.props.customerDetail?.shiftDateDeparture}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6} className="timeInput">
                <TimePickerGCalendar
                  text={t("service.arrival.time")}
                  value={this.state.requestArrivalTimeDeparture ?? this.props.customerDetail?.requestArrivalTimeDeparture ?? '10:00 pm'}
                  name={"requestArrivalTimeDeparture"}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>{
                    this.handleValue("requestArrivalTimeDeparture", value)
                    //this.handleValue("startTimeDeparture", value)
                  }
                  }
                  defaultValue="02:00"
                  invalid={!this.state.requestArrivalTimeDeparture || !this.props.customerDetail?.requestArrivalTimeDeparture}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  value={this.state.distance_suggested}
                  text={`${t("service.distance")}`}
                  disabled={this.props.disabled}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  disabled={this.props.disabled}
                  type="text"
                  value={this.state.time_end_suggested}
                  text={`${t("service.time_start_destination")}`}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customer={this.props.customerDetail}
                location={this.state.currentLocation}
                directionsIda={this.state.directionsIda}
                waypoints={this.state.waypoints}
                nosocomio={
                  this.state.hospitalselected
                }
                googleMapURL="/googlemap"
                mode="ida"
                center={center}
                colorStroke={"#01abc2"}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `520px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
          </GridItem>
          </GridContainer>
        </Card>
      );
    }else if (type === "vuelta") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date()).format( "YYYY-MM-DD" );
        max = moment(new Date()).format( "YYYY-MM-DD");
      }
      return (
        <Card id="card-customer" className="card-customer-form">
          <CardHeader icon>
              <CardIcon color="primary">
                  <Car />
              </CardIcon>
              <h3 className="card-icon-title">
                {t("service.configuration")}
              </h3>
          </CardHeader>
          <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
          <GridItem xs={12} className="margin-left">
              <h2 >{t("service.returnTrip.label")}</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t('service.transferType')}
                elements={this.state.traslados}
                disabled={this.props.disabled}
                onSelectedValue={(value) => this.handleValue("trasladoVuelta", value)}
                value={this.state.trasladoVuelta ?? this.props.customerDetail?.trasladoVuelta}
              />
            </GridItem>
          <GridItem xs={12} className="margin-left">
              <div style={{'display':'flex','alignItems': 'center' }}>
                  <AccountBox/> 
                  <h4 >{t("service.origin")}</h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={`${t("service.medical.provider")}`}
                elements={this.props.prestadores.length > 0 ? this.props.prestadores : this.state.prestadores}
                disabled={this.props.disabled}
                onSelectedValue={(value) =>{
                  this.handleValue("prestadorVuelta", value)
                  this.handleValue("prestadorResumen", value)
                }
                }
                value={this.props.customerDetail?.prestadorVuelta ?? this.state.prestadorVuelta}
              />
            </GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
                <SelectInput
                  label={`${t("service.hospital")}`}
                  elements={this.props.hospitals.length > 0 ? this.props.hospitals : this.state.nosocomios}
                  disabled={this.props.transferWay !== 3 ? false : true}
                  onSelectedValue={(value) =>
                    this.handleValue("nosocomioVuelta", value)
                  }
                  value={this.props.transferWay !== 3 ?
                    this.props.customerDetail?.nosocomioVuelta ?? this.state.nosocomioVuelta:
                  this.props.customerDetail?.nosocomio ?? this.state.nosocomio }
                />
            </GridItem>
              
           

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <DateInput
                  text={`${t("service.retirement.date")}`}
                  disabled={this.props.disabled}
                  minDate
                  validation={true}
                  typeValidation={'range'}
                  min={this.props.startDate}
                  max={this.props.endDate}
                  onChangeValue={(value) =>{
                    this.handleValue("shiftDateReturn", value)
                    this.handleValue("arrivalDateReturn", value)
                  }
                  }
                  value={this.state.shiftDateReturn ?? this.props.customerDetail?.shiftDateReturn}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6} className="timeInput">
                <TimePickerGCalendar
                  text={`${t("service.requested.pickup.time")}`}
                  value={this.state.requestArrivalTimeReturn ?? this.props.customerDetail?.requestArrivalTimeReturn ?? '10:00 pm'}
                  name={"requestArrivalTimeReturn"}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>{
                    this.handleValue("requestArrivalTimeReturn", value)
                    //this.handleValue("withdrawalTimeReturn", value)
                  }
                  }
                  defaultValue="02:00"
                  invalid={!this.state.requestArrivalTimeReturn || !this.props.customerDetail?.requestArrivalTimeReturn}
                />
              </GridItem>
            </GridContainer>
            
            <GridItem xs={12} className="margin-left">
              <div style={{'display':'flex','alignItems': 'center' }}>
                  <Gps/> 
                  <h4 className="label-services">{t("service.destination")}</h4>
              </div>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
              <ValidationInput
                type="text"
                //text="Dirección"
                disabled={true}
                value={
                  this.props.customerDetail ? this.props.customerDetail.address?.[0].street +" "+ this.props.customerDetail.address?.[0].number : ""
                }
              />
            </GridItem>
            <GridContainer>
              <GridItem  xs={12} sm={6}>
                <ValidationInput
                    disabled={true}
                    value={
                      this.props.customerDetail ? this.props.customerDetail.address?.[0].floor +" " : ""
                    }
                    type="text"
                    text={`${t("service.floor")}`}
                  />
              </GridItem>
              <GridItem  xs={12} sm={6}>
                <ValidationInput
                    disabled={true}
                    type="text"
                    text={`${t("service.apartment")}`}
                    value={
                      this.props.customerDetail
                        ? this.props.customerDetail.address?.[0].department +" "
                        : ""
                    }
                  />
                  
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <DateInput
                  text={`${t("service.estimated.date.arriving")}`}
                  disabled={true}
                  minDate
                  min={this.props.startDate}
                  max={this.props.endDate}
                  name={"arrivalDateReturn"}
                  onChangeValue={(value) =>
                    this.handleValue("arrivalDateReturn", value)
                  }
                  value={this.state.arrivalDateReturn ?? this.props.customerDetail?.arrivalDateReturn}
                />
              </GridItem>
              <GridItem xs={11} sm={5} md={5} className="timeInput">
                <TimePickerGCalendar
                  text={`${t("service.estimated.time.arriving")}`}
                  value={this.state.withdrawalTimeReturn ?? this.props.customerDetail?.withdrawalTimeReturn ?? '10:00 am'}
                  name={"withdrawalTimeReturn"}
                  disabled={true}
                  onSelectedValue={(value) =>
                    this.handleValue("withdrawalTimeReturn", value)
                  }
                  defaultValue="02:00"
                  invalid={!this.state.withdrawalTimeReturn || !this.props.customerDetail?.withdrawalTimeReturn} 
                />
              </GridItem>
              <Grid item xs={1} className="info-search" style={{ marginTop: '20px' }}>
                <Tooltip aria-label="info" title={t('tooltipInfo.wizzardVuelta')} placement="left-start">
                  <InfoOutlined />
                </Tooltip>
              </Grid>
            </GridContainer>
            
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            
          <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  disabled={this.props.disabled}
                  text={`${t("service.distance")}`}
                  value={this.state.distance_suggested_return}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  disabled={this.props.disabled}
                  text={`${t("service.time_start_destination")}`}
                  value={this.state.time_end_suggested_return}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customerVuelta={this.props.customerDetail}
                location={this.state.currentLocation}
                directionsVuelta={this.state.directionsVuelta}
                waypoints={this.state.waypoints}
                nosocomioVuelta={
                  this.state.hospitalselectedVuelta
                }
                googleMapURL="/googlemap"
                mode="vuelta"
                center={center}
                colorStroke={"#01abc2"}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `520px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
          </GridItem>
          </GridContainer>
        </Card>
      );
    }
    else if (type === "resumen") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date()).format(
          "YYYY-MM-DD"
        );
        max = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      return (
        <Card id="card-customer" className="card-customer-form">
          <CardHeader icon>
              <CardIcon color="primary">
                  <Car />
              </CardIcon>
              <h3 className="card-icon-title">{t("service.configuration")}</h3>
          </CardHeader>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SelectInput
                  label={`${t("service.transfer.provider")}`}
                  elements={this.props?.prestadores?.length > 0 ? this.props?.prestadores : this.state?.prestadores}
                  disabled={true}
                  onSelectedValue={(value) =>
                    this.handleValue("prestadorResumen", value)
                  }
                  value={this.props.customerDetail?.prestadorResumen ?? this.state.prestadorResumen}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <SchedulePlan
                  isTraslado={true}
                  insideModule={false}
                  current={this.state.recurrence}
                  indexArrayDays={0}
                  disabled={this.props.disabled}
                  invalidPlan={this.invalidPlan}
                  onCreatedRecurrence={this.onCreatedRecurrence}
                  className="schedule-plan-component"
                  onlyDate={true}
                />
              </GridItem>
              </GridContainer>
              
              <GridItem xs={12} sm={12} md={12}>
                <RegularMap
                  customer={this.props.customerDetail}
                  location={this.state.currentLocation}
                  directionsIda={this.state.directionsIda}
                  directionsVuelta={this.state.directionsVuelta}
                  waypoints={this.state.waypoints}
                  nosocomio={
                    this.state.hospitalselected
                  }
                  googleMapURL="/googlemap"
                  mode="idaVuelta"
                  center={center}
                  colorStroke={"#01abc2"}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `520px`,
                        borderRadius: "6px",
                        overflow: "hidden",
                        width: `100%`,
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </GridItem>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} className="componentType">
              {(this.props.transferWay === 1 || this.props.transferWay === 3)&&
              <>
                <GridItem xs={12} className="margin-left">
                  <h2 >{t("service.oneWay.label.summary")}</h2>
                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <ValidationInput
                      type="text"
                      text={`${t("service.estimated.distance")}`}
                      disabled={true}
                      value={this.state.distance_suggested}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <ValidationInput
                      type="text"
                      text={`${t("service.estimated.time")}`}
                      disabled={true}
                      value={this.state.time_end_suggested}
                    />
                  </GridItem>
                </GridContainer>
              </>
              }
              
              {(this.props.transferWay === 2 || this.props.transferWay === 3)&&
                <>
                <GridItem xs={12} className="margin-left">
                <h2 >{t("service.returnTrip.label.summary")}</h2>
              </GridItem>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <ValidationInput
                    type="text"
                    text={`${t("service.estimated.distance")}`}
                    disabled={true}
                    value={this.state.distance_suggested_return}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <ValidationInput
                    type="text"
                    text={`${t("service.estimated.time")}`}
                    disabled={true}
                    value={this.state.time_end_suggested_return}
                  />
                </GridItem>
              </GridContainer>
                </>
              
              }
             
              
            </GridItem>
          
          </GridContainer>
        </Card>
      );
    }
    else {
      let date = new Date();
      date = moment(date).format("DD-MM-YYYY");

      let distance_recorred_str = this.state.distance_recorred / 1000 + " Km";
      let time_recorred_str = this.formatTimeSeconds();
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className="componentType">
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.scheduleId")}
                  value={this.props.event.id}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={this.props.disabled}
                  labelText={t("service.start_date")}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={date}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  text={t("service.customer.address")}
                  inputProps={{ style: { width: "100%" } }}
                  disabled={true}
                  value={this.props.appointment.customer.address_name}
                />
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.startTimeProgReal")}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.endTimeEstimated")}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  labelText={t("service.traslado_detail")}
                  disabled={true}
                  value={memory.tempService.traslado.value}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.driver")}
                  disabled={true}
                  value={
                    memory.tempService.driver
                      ? memory.tempService.driver.value
                      : "-"
                  }
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  text={t("service.nosocomio.address")}
                  elements={this.state.nosocomios}
                  disabled={true}
                  value={
                    memory.tempService.nosocomio.value +
                    " - " +
                    memory.tempService.nosocomio.address
                  }
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  text={t("service.distance_recorred")}
                  disabled={true}
                  value={distance_recorred_str}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  text={t("service.time_recorred")}
                  disabled={true}
                  value={time_recorred_str}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  value={this.state.distance_suggested}
                  disabled={this.props.disabled}
                  text={`${t("service.distance")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  value={this.state.time_end_suggested}
                  text={`${t("service.time_start_destination")}`}
                  disabled={this.props.disabled}
                />
              </GridItem>
            </GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customer={this.props.customer}
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                center={center}
                path1={this.state.path1}
                path2={this.state.path2}
                nosocomio={
                  this.props.appointment
                    ? this.props.appointment.services.nosocomio
                    : {}
                }
                googleMapURL="/googlemap"
                mode="inprogress"
                colorStroke={"#01abc2"}
                pathExtra={this.state.pathExtra}
                currentPosition={this.state.currentPosition}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `520px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
      );
    }
  }
}

DialogoTraslado.defaultProps = {
  onGetModuleTypes: () => [],
  onGetPracticeTypes: () => [],
};

DialogoTraslado.propTypes = {
  t: PropTypes.func,
  hospitals: PropTypes.array,
  prestadores: PropTypes.array,
  editData: PropTypes.array,
  customerDetail: PropTypes.object,
  action: PropTypes.string,
  handleChangeValue: PropTypes.func,
  viewMode: PropTypes.string,
  appointment: PropTypes.object,
  transferWay: PropTypes.number,
};

export default withTranslation()(DialogoTraslado);
